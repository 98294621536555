/* -----------------------------Map Container----------------------------------------- */
.my-text-primary,.text-primary {
  color: #203071;
}

.map-container {
  width: 60%;
  display: block;
  text-align: center;
  border-radius: 20px;
  box-shadow: 1px 1px gray;
  padding: 20px;
  color: #203071;
  margin-top: -70px !important;
}

@media (max-width: 767px) {
  .map-container {
    width: 86%;
  }
}

.btn {
  color: white;
  background-color: #203071;
  border-radius: 20px;
  width: 150px;
  margin-top: 40px;
}

.card-btn {
  color: white;
  background-color: #203071;
  border-radius: 20px;
  width: 70%;
}
.btn:hover {
  color: #e3ef0d;
}

/* --------------adds Container------------------------------------- */



/* --------------news Cards------------------------------------- */
.card-img-top{
  max-height: 391.500px;
  max-width: 522px;
  min-height: 277px;
  min-width: 277px;
  border-radius: 10px 10px 0 0;
  }
  .card{
    min-width: 286px;
    min-height: 286px;
    max-width: 522px;
    border-radius: 10px;
    border: 1px solid #eee
  } 

/*---------------- إعلان رقم 2 --------------------------*/

.background {
  /* background: url("/images/Tripoli-1.webp"); */
  background: url("/public/images/Tripoli-1.webp");
  position: relative;
  height: 16rem;
  margin-bottom: 8rem;
}

.layer {
  background-color: #203071;
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ----------------------decisions-container------------------ */

.decision-1 {
  background-color: #d9e6f0;
  border-radius: 4%;
}

/* ----------------------departments-container------------------ */

.my-list-group button:hover {
  background-color: #203071;
  color: white;
}
.my-list-group button:active {
  background-color: #203071;
  color: white;
}
.my-bg-light {
  background-color: white;
  color: black;
  margin-top: 1rem;
  width: 326px;
}
.btn-active {
  background-color: #203071;
  color: white;
  margin-top: 1rem;
  width: 326px;
}

.my-btn-close{
  background-color: #fff;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .report-panner{
    font-size: 1rem;
  }
.report-btn-img{
  width: 100px;
}
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 285px) {
  .report-panner{
    font-size: 0.8rem;
  }
.report-btn-img{
  width: 150px;
  display: none;
}
}



/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .report-panner{
    font-size: 1rem;
  }
  .report-btn-img{
    width: 150px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .report-panner{
    font-size: 1.3rem;
  }
  .report-btn-img{
    width: 200px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .report-panner{
    font-size: 1.5rem;
  }
  .report-btn-img{
    width: 200px;
  }
}
