/* #203071 blue */
/* #e3ef0d yellow */
/* -----------------------------Header----------------------------------------- */


.header {
  height: 350px;
  color: white; 
  width: 100%;
  background-color: #1C2C6A;
  min-width: 339px;
}

@media (max-width:767px) {
  .header {
    height: 200px;
  }
}


/* -----------------------------Nav----------------------------------------- */

.a-link-active {
  color: #203071;
  border-bottom: 3px solid #e3ef0d;
  margin: 0 auto;
  font-weight: bold;
} 
