*{
    box-sizing: border-box;  
    margin:0;
    padding:0;
}
.container{
    max-width: 1200px;
    margin: 0 auto;
  
  }
body{
    margin: 0;
    font-family: 'Cairo', sans-serif;
}
.root{
    width: 100vw;
    margin : 0 auto
}
/* small screen */
@media (max-width:767px) {
    .img-icon{
        height: 10px;
        width: 10px;
    }
    button{
        font-size:10px !important;
    }
  }


/* larg screen  */
@media (min-width:767px) {

    .img-icon{
        height: 15px;
        width: 15px;
    }

  }




  /* width */
::-webkit-scrollbar {
  height: 5px;
    width: 5px;
  }
  
  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #203071; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .main-admin{

    min-height: 82vh;
  }