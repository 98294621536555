nav ol li{
    color:#1C2C6A;
}
nav ol li a{
    color:#1C2C6A;
    text-decoration: none;
}
nav ol li a:hover{
    color:#1C2C6A;
    text-decoration: none;
}
.active-btn{
    color:#1C2C6A;
}


.avatar {
    vertical-align: middle;
    width: 125px;
    height: 125px;
    border-radius: 50%;
}
.scroll {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    
}
.scroll-item{
 
    background-color:'#BCCC6CC';

    display: inline-block;
}

.my-button {
    color: white;
    background-color: #203071;
    border-radius: 5px;
    
    height: 38px;
    border: 0.2px solid #203071;

  }
.my-button:hover{
    color: #e3ef0d;
    background-color: #203071;
    border-radius: 5px;

    height: 38px;
    border: 0.2px solid #e3ef0d;
}
.my-button-active{
    color: #203071;
    background-color: #e3ef0d;
    border-radius: 5px;

    height: 38px;
    border: 0.2px solid #e3ef0d;
}  
.department-image{
    width: 90%;
    height: 90%;
}
