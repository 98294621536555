.my-btn{
    color: #203071;
    text-align: center;
    background-color: #F9FAFB;
    border: 0.2px solid #203071;
    border-radius: 8px;
    width: 150px;
    /* margin-top: 40px; */
    height: 38px;
}
.my-btn2{
    color: white;
    text-align: center;
    background-color: #203071;
    border: 0.2px solid #203071;
    border-radius: 8px;
    width: 150px;
    /* margin-top: 40px; */
    height: 38px;
}
.my-btn2:hover{
    color:#e3ef0d
}
.my-hover:hover{
color: #e3ef0d;
background-color: #203071;
cursor: pointer;

}
