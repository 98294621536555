/* #203071 blue */
/* #e3ef0d yellow */
/* -----------------------------Header----------------------------------------- */
.footer {
    height: 400px;
    width: 100%;
    color: white;
    background-image:url('/public/images/Rectangle-16.webp');
    background-color: #1C2C6A;
    margin-top: 1rem;
    min-width: 339px;
  }

  
  .footer-layer {
    background-color: #203071;
    opacity: 0.9;
    width: 100%;
    height: 100%;
  }

  .nav-footer-item{
    color: white;
  }
  .nav-footer-item:hover{
    color: #e3ef0d;
  }

  .footer-icon{
    color:#e3ef0d;

  }
  .my-soial-icon{
    color:white;
  }
  .my-soial-icon:hover{
    color:#e3ef0d
  }


  @media (max-width:767px) {
    .my-fs {
      font-size: 0.6rem;
    }
    .my-fs-2 {
      font-size: 0.6rem;
    }
    .footer-icon{
      height: 15px;
      width: 15px;
    }
    .my-footer-image{
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width:767px) {

    .footer-icon{

      height: 20px;
      width: 20px;
    }
    .my-fs {
      font-size: 0.9rem;
    }
    .my-footer-image{
      width: 60%;
      height: 60%;
    }
  }